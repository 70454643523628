import React, { Component } from 'react';
import { AwardsandPrizes } from './CallForRegistration/AwardsandPrizes';
import { CategoryComparison } from './CallForRegistration/CategoryComparison';
import { CompetitionGuidelines } from './CallForRegistration/CompetitionGuidelines';
import { ImportantDates } from './CallForRegistration/ImportantDates';
import { RegistrationFees } from './CallForRegistration/RegistrationFees';
import { RegistrationSubmission } from './CallForRegistration/RegistrationSubmission';
import CustomFooter from './CustomFooter';

export class CallForRegistrationBoard extends Component {
    static displayName = CallForRegistrationBoard.name;

    constructor(props) {

        super(props);
        this.state = {
          
        };
    }
    render() {

        return (
            <div >
                <div class="section-full">

                    {/*<div>&nbsp;</div>*/}
                    {/*<section id="competitionguidelines" class="section-competitionGuidelines">*/}
                    {/*    <CompetitionGuidelines />*/}
                    {/*</section>*/}

                    {/*<div>&nbsp;</div>*/}
                    {/*<section id="registrationsubmission" class="section-margin">*/}
                    {/*    <RegistrationSubmission />*/}
                    {/*</section>*/}

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#competitionguidelines">
                        <CompetitionGuidelines />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#registrationsubmission">
                        <RegistrationSubmission />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#importantdates">
                        <ImportantDates />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#registrationfees">
                        <RegistrationFees />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#awardandprize">
                        <AwardsandPrizes />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/call-for-registration#categorycomparison">
                        <CategoryComparison />
                    </section>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <CustomFooter />

                </div>
      </div>
    );
  }
}
