import React, { Component } from 'react';


export class CompetitionGuidelines extends Component {
    static displayName = CompetitionGuidelines.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <div>

                    <section id="title">
                        <div class="margin-centerText">
                            <div style={{ fontSize: '24px' }}>
                                <strong>Competition Guidelines</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </section>

                    <section id="content">
                        <div class="competitionContent">
                            <div class="row" style={{ placeContent: 'center'}}>
                                <div class="col-12 col-md-8">
                                    <ol>
                                        <li style={{ padding: 10 }}>JIIICaS 2024 is open to all local and international academicians and students.</li>
                                        <li style={{ padding: 10 }}>Each participation should consist of at most 5 members and 1 team leader.</li>
                                        <li style={{ padding: 10 }}>The team leader must be the student.</li>
                                        <li style={{ padding: 10 }}>The combination of staff, lecturers, teachers, and students in a group is allowed.</li>
                                        <li style={{ padding: 10 }}>The staff/ lecturers/ teachers should not exceed 2 people per group.</li>
                                        <li style={{ padding: 10 }}>The category should follow the category of the students in the group.</li>
                                        <li style={{ padding: 10 }}>Physical competition is only open for Category A. </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
      </div>
    );
  }
}
