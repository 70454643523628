import React, { Component } from 'react';
import categoryComparison from '../../image/category_comparison.png'

export class CategoryComparison extends Component {
    static displayName = CategoryComparison.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Category Comparison</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="category" class="margin-categoryComparison">
                    <img class="scaleCategoryComparison" src={categoryComparison} alt="categoryComparison" />
                </section>
      </div>
    );
  }
}
