import React, { Component } from 'react';
import importantDates from '../../image/important_dates_v3.png';
export class ImportantDates extends Component {
    static displayName = ImportantDates.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Important Dates</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section id="category">*/}
                {/*    */}{/*<div>*/}
                {/*    */}{/*    <div class="row" style={{ placeContent: 'center' }}>*/}
                {/*    */}{/*        <div class="col-12 col-md-10">*/}
                {/*    */}{/*            <table id="tableWithHeader">*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <th></th>*/}
                {/*    */}{/*                    <th>Online</th>*/}
                {/*    */}{/*                    <th>Physical</th>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Call for Registration</td>*/}
                {/*    */}{/*                    <td>1 March 2024</td>*/}
                {/*    */}{/*                    <td>1 March 2024</td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Abstract Submission Deadline</td>*/}
                {/*    */}{/*                    <td>1 June 2024</td>*/}
                {/*    */}{/*                    <td>1 June 2024</td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Extended Abstract Submission Deadline (Category A)</td>*/}
                {/*    */}{/*                    <td>30 August 2024</td>*/}
                {/*    */}{/*                    <td>30 August 2024</td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Extended Abstract Submission Deadline(Category B & C)</td>*/}
                {/*    */}{/*                    <td><strong>N/A</strong></td>*/}
                {/*    */}{/*                    <td><strong>N/A</strong></td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Early Bird Payment Deadline</td>*/}
                {/*    */}{/*                    <td>30 May 2024</td>*/}
                {/*    */}{/*                    <td>30 May 2024</td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Normal Fees Deadline</td>*/}
                {/*    */}{/*                    <td>31 July 2024</td>*/}
                {/*    */}{/*                    <td>31 July 2024 </td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Video Submission Deadline</td>*/}
                {/*    */}{/*                    <td>1 September 2024</td>*/}
                {/*    */}{/*                    <td><strong>N/A</strong></td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Judging Period</td>*/}
                {/*    */}{/*                    <td>23-30 September 2024</td>*/}
                {/*    */}{/*                    <td>13 October 2024 </td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*                <tr>*/}
                {/*    */}{/*                    <td>Poster Presentation</td>*/}
                {/*    */}{/*                    <td><strong>N/A</strong></td>*/}
                {/*    */}{/*                    <td>13 October 2024 </td>*/}
                {/*    */}{/*                </tr>*/}
                {/*    */}{/*            </table>*/}
                {/*    */}{/*        </div>*/}
                {/*    */}{/*    </div>*/}
                {/*    */}{/*</div>*/}
                {/*</section>*/}
                <section id="category" class="margin-categoryComparison">
                    <img class="scaleCategoryComparison" src={importantDates} alt="importantDates" />
                </section>
      </div>
    );
  }
}
