import React, { Component } from 'react';
import Blink from 'react-blink-text'; 

export class RegistrationFees extends Component {
    static displayName = RegistrationFees.name;

    constructor(props) {

        super(props);
        this.state = {
            paynowAOnline: "https://uitmpay.uitm.edu.my/otherservices/products/AAJ3/02/2981",
            paynowAPhysical: "https://uitmpay.uitm.edu.my/otherservices/products/AAJ3/02/2982",
            promotion50:"https://uitmpay.uitm.edu.my/otherservices/products/AAJ3/02/3031"
        };
    }

    render() {

        return (
            <div >
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Registration Fees</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="category">
                    <div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-10">
                                <table id="tableWithHeader">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Online</th>
                                        <th>Physical</th>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">Category A Higher Education Students </td>
                                        <td>Early Bird</td>
                                        <td>RM80 | USD25 </td>
                                        <td>RM230 | USD55  </td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                        <td>Normal fees</td>
                                        <td>RM100 | USD30</td>
                                        <td>RM250 | USD60 </td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'white' }}>
                                        <td>Category B Primary and Secondary School Students </td>
                                        <td></td>
                                        <td>FREE</td>
                                        <td>N/A </td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                        <td>Category C Special Education Students </td>
                                        <td></td>
                                        <td>FREE </td>
                                        <td>N/A </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-10">
                                <p>
                                    <strong>*Payments made are not refundable. </strong>
                                </p>
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        {/*Promotion section*/ }

                        {/*<div class="row" style={{ placeContent: 'center', textAlign: 'center', margin: 10 }}>*/}
                        {/*    <p><strong>Promotion: From 23/3/2024 until 30/3/2024 :</strong></p>*/}
                        {/*    <div>&nbsp;</div>*/}
                        {/*    <h3 className="blink"*/}
                        {/*        style={{ color: "black" }}>*/}
                        {/*        <a href={this.state.promotion50} ><button class="button register">Promotion RM 50 (Online)</button></a>*/}
                        {/*    </h3> */}
                        {/*</div>*/}

                        <div class="row" style={{ placeContent: 'center', textAlign: 'center', margin: 10 }}>
                            <p><strong>For local participants:</strong></p>
                            <div>
                                <a href={this.state.paynowAOnline} ><button class="button register">Pay Now &mdash; Category A (Online)</button></a>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <a href={this.state.paynowAPhysical}><button class="button register">Pay Now &mdash; Category A (Physical)</button></a>
                            </div>

                        </div>

                        <div>&nbsp;</div>

                        <div class="row" style={{ placeContent: 'center', margin: 10 }}>
                            <p style={{textAlign: 'center'}}><strong>For international participants:</strong></p>

                            <div class="scaleBankDetailTable">
                                <table id="tableWithoutHeader">

                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Account Number</td>
                                        <td>01087010049990 </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Bank</td>
                                        <td style={{ backgroundColor: 'white' }}>Bank Islam Malaysia Berhad </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Name</td>
                                        <td>Bank Islam Pasir Gudang </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Swift Code</td>
                                        <td style={{ backgroundColor: 'white' }}>BIMBMYKLXXX </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Reference</td>
                                        <td>JIIICaS2024_Participant ID </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Address</td>
                                        <td style={{ backgroundColor: 'white' }}>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>Ground & First Floor, Lot 112719 </li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}> No. 11, Pusat Perdagangan Pasir Gudang</li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>Jalan Bandar 81700 Pasir Gudang, Johor </li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ backgroundColor: '#9bf2fa' }}>Telephone</td>
                                        <td style={{ backgroundColor: 'white' }}>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>+607-2526671  </li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>+607-2526672 </li>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </section>

      </div>
    );
  }
}
