import React, { Component } from 'react';
import RegistrationProcess from '../RegistrationSubmission/RegistrationProcess';
import VideoGuideline from '../RegistrationSubmission/VideoGuideline';
import PosterGuideline from '../RegistrationSubmission/PosterGuideline';
import JudgingCriteria from '../RegistrationSubmission/JudgingCriteria';


export class RegistrationSubmission extends Component {
    static displayName = RegistrationSubmission.name;

    constructor(props) {

        super(props);
        this.state = {
            extendedEnglishVersionLink:"https://docs.google.com/document/d/1SMUk2Z6DXB2wUBEFmM60cY_Uf-0N0uyR/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            extendedMalayVersionLink: "https://docs.google.com/document/d/1LFubjXYm93X2CG75MUNT6ayORAb5cm6N/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractEnglishVersionLink: "https://docs.google.com/document/d/1aqocxA2FMb3_AJBKI6_ja53gHhYJHzVh/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractMalayVersionLink: "https://docs.google.com/document/d/1nWyQ--6s01eqTU0Cy-N5I0BSS12zcl1_/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true ",
            cmtLink: "https://cmt3.research.microsoft.com/JIIICaS2024"
        };
    }

    render() {

        return (
            <div >
                <div class="margin-CenterText">
                    <RegistrationProcess />
                    <VideoGuideline />
                    <PosterGuideline />
                    <JudgingCriteria />
                </div>

      </div>
    );
  }
}
