import React, { Component } from 'react';

export default class Countdown extends Component {
    static displayName = Countdown.name;

    constructor(props) {

        super(props);
        this.state = {
     };
    }

    render() {

        return (
            <div>
               {/* https://www.timeanddate.com/clocks/freecountdown.html*/}
                <section id="title">
                    <div class="margin-countdown">
                        <iframe src="https://free.timeanddate.com/countdown/i98tcjy9/n122/cf100/cm0/cu4/ct0/cs0/cac1096a4/co1/cr0/ss0/cac1096a4/cpc1096a4/pcfff/tc000/fs300/szw320/szh135/tatEvent%20in/tac1096a4/tptEvent%20started%20in/tpc1096a4/iso2024-10-13T00:00:00/pa20" allowtransparency="true" frameborder="0" width="360" height="175"></iframe>
               </div>
                </section>
      </div>
    );
  }
}
