import React, { Component } from 'react';
import kjnLogo from '../../image/kjn_logo.png';
import mbjbLogo from '../../image/mbjb_logo.png';
import utmLogo from '../../image/utm_logo.png';
import pisLogo from '../../image/pis_logo.png';
import unibaLogo from '../../image/uniba_logo.png';
import kpjnjLogo from '../../image/kpjnj_logo.png';
import irdaLogo from '../../image/irda_logo.png';
import masmedLogo from '../../image/masmed_logo.png';
import entclubLogo from '../../image/entclub_logo.png';
import kppimLogo from '../../image/kkpim_logo.png';
import kkbpLogo from '../../image/kkbp_logo.png';
import uniklLogo from '../../image/unikl_logo.png';
import kpmLogo from '../../image/kpm_logo.png';


export default class CollaborationPartner extends Component {
    static displayName = CollaborationPartner.name;
    render() {

        return (
            <div style={{ padding: "50px", backgroundColor: "#9bf2fa" }}>
                <div class="margin-centerText">
                    <div style={{ fontSize: '24px' }}>
                        <strong>Collaboration Partner</strong>
                        <hr style={{ width: '10%', margin: 'auto' }}></hr>
                        <div>&nbsp;</div>
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>

                    <div class="row" style={{ placeContent: 'center' }} >
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td> <img class="scaleCollabLogo" src={kpmLogo} alt="kpmLogo" /></td>
                                </tr>

                            </div>
                        </div>
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td>  <img class="scaleCollabLogo" src={entclubLogo} alt="entclubLogo" /></td>
                                </tr>
                            </div>
                        </div>
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td>  <img class="scaleCollabLogo" src={kppimLogo} alt="kppimLogo" /></td>
                                </tr>
                            </div>
                        </div>

                    </div>

                    <div class="row" style={{ placeContent: 'center' }} >
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td> <img class="scaleCollabLogo" src={masmedLogo} alt="masmedLogo" /></td>
                                </tr>

                            </div>
                        </div>
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td>  <img class="scaleCollabLogo" src={utmLogo} alt="utmLogo" /></td>
                                </tr>
                            </div>
                        </div>

                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                    <td> <img class="scaleCollabLogo" src={pisLogo} alt="pisLogo" /></td>
                                    </tr>
                                </div>
                            </div>
                    </div>

                    <div class="row" style={{ placeContent: 'center' }} >
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td> <img class="scaleCollabLogo" src={unibaLogo} alt="unibaLogo" /></td>
                                </tr>

                            </div>
                        </div>
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center', height:'60%' }}>
                            <div>
                                <tr>
                                    <td>  <img class="scaleCollabLogo" src={uniklLogo} alt="uniklLogo" /></td>
                                </tr>
                            </div>
                        </div>
                        <div class="col-10 col-md-3" style={{ alignSelf: 'center', height: '60%' }}>
                            <div>
                                <tr>
                                    <td>  <img class="scaleCollabLogo" src={kkbpLogo} alt="kkboLogo" /></td>
                                </tr>
                            </div>
                        </div>
                    </div>

                        {/*<div class="row" style={{ placeContent: 'center'}} >*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={unibaLogo} alt="unibaLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Universitas Batam </strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td>  <img class="scaleCollabLogo" src={pisLogo} alt="pisLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Politeknik Ibrahim Sultan</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>                    */}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td>  <img class="scaleCollabLogo" src={kpjnjLogo} alt="kpjnjLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Kementerian Pendidikan Jabatan Pendidikan Negeri Johor</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={mbjbLogo} alt="mbjbLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Majlis Bandaraya Johor Bahru</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>         */}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={kjnLogo} alt="kjnLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Jata Negeri Johor</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>     */}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div class="row" style={{ placeContent: 'center' }} >*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={utmLogo} alt="utmLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Universiti Teknologi Malaysia</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td>  <img class="scaleCollabLogo" src={irdaLogo} alt="irdaLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Iskandar Regional Development Authority</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td>  <img class="scaleCollabLogo" src={masmedLogo} alt="masmedLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Kementerian Pendidikan Jabatan Pendidikan Negeri Johor</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={entclubLogo} alt="entclubLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Majlis Bandaraya Johor Bahru</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div class="col-10 col-md-2" style={{ alignSelf: 'center' }}>*/}
                        {/*        <div>*/}
                        {/*            <tr>*/}
                        {/*                <td> <img class="scaleCollabLogo" src={kppimLogo} alt="kppimLogo" /></td>*/}
                        {/*            </tr>*/}
                        {/*            */}{/*<tr>*/}
                        {/*            */}{/*    <td><strong>Jata Negeri Johor</strong></td>*/}
                        {/*            */}{/*</tr>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>
                </div>
                );
    }
}
