import React, { Component } from 'react';
import { aboutUsContentP1, aboutUsContentP2, aboutUsContentP3 } from '../../content/HomeContent'
import figure from '../../image/about-us_figure.png'

export default class AboutUs extends Component {
    static displayName = AboutUs.name;
    constructor(props) {

        super(props);
        this.state = {
            aboutUsContentP1: aboutUsContentP1,
            aboutUsContentP2: aboutUsContentP2,
            aboutUsContentP3: aboutUsContentP3
        }
    }

    render() {

        return (
            <div >
                <div class="responsive-container-block bigContainer">
                    <div class="responsive-container-block Container">
                        <img class="mainImg" src={figure} alt="figure"></img>
                            <div class="allText aboveText">
                            <div style={{ fontSize: '24px' }}>
                                <strong>ABOUT JIIICaS2024 </strong>
                            </div>
                            <div>&nbsp;</div>
                            <p style={{ textAlign: 'justify' }}>{this.state.aboutUsContentP1}<strong>{this.state.aboutUsContentP2}</strong> {this.state.aboutUsContentP3}</p>
                            <div>&nbsp;</div>
                            {/*<a href="/#/call-for-registration"><button class="button register">Register Now</button></a>*/}
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}
