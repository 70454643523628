import React, { Component } from 'react';

export default class VideoGuideline extends Component {
    static displayName = VideoGuideline.name;

    constructor(props) {

        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px", }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Video Guidelines</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div>
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <ol>
                                    <li style={{ padding: 10 }}>Video can be in Bahasa Melayu or English. </li>
                                    <li style={{ padding: 10 }}>Video should be between 7 to 10 minutes in length. Videos that are not in between the length mentioned, marks will be deducted.  </li>
                                    <li style={{ padding: 10 }}>All information presented in the video must be cited, giving credit to the source.</li>
                                    <li style={{ padding: 10 }}>Plagiarism of any kind will result in disqualification. </li>
                                    <li style={{ padding: 10 }}>Video Contents: Introduction to the project, problem statement, objective, explanation of the innovative product (idea, strategy, program, or project), commercialization potential (if any), and any other supporting evidence. </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
      </div>
    );
  }
}
