import React, { Component } from 'react';

export class Award extends Component {
    static displayName = Award.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Awards</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" class="section-award">
                    <div class="margin-centerText">
                        <h2>The list of award recipients will be announced on 13th October 2024. </h2>
                    </div>
                </section>

      </div>
    );
  }
}
