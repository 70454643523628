import React, { Component } from 'react';
import visitJohor from '../../image/visitJohor.jpg';

export class VisitJohor extends Component {
    static displayName = VisitJohor.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#9bf2fa" }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Visit Johor</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div class="margin-centerText">
                        <a href="https://www.adotrip.com/blog/places-to-visit-in-johor">
                            <img class="scaleImage" src={visitJohor} alt="visitJohor" />
                        </a>
                    </div>
                </section>
            </div>
    );
  }
}
