export const title = "JOHOR INTERNATIONAL INNOVATION INVENTION COMPETITION AND SYMPOSIUM 2024";
export const aboutUsContentP1 = "The Johor International Innovation, Invention, Competition, and Symposium 2024 (JIIICaS 2024) aims to foster innovation activities in Johor and highlight local expertise with the support of UiTM. Aligned with the objectives of the Chief Minister of Johor, the event seeks to drive social innovation for societal benefit. The theme";
export const aboutUsContentP2 = " 'Flourish and Nurturing Sustainable Innovation for a Prosperous Nation,'";
export const aboutUsContentP3 = " underscores the commitment to sustainable innovation and national prosperity.Started in 2017 as IDEAS Competitions, the event evolved into JIICaS in 2023 to include participants from primary, secondary, and higher learning institutions.This year's hybrid event offers both online and physical exhibitions, inviting students from various fields to showcase their inventions and innovations. Participants can submit products, e-content, research, or concepts across disciplines, emphasizing both social science and science technology. ";
export const themeContent = "Flourish and Nurturing Sustainable Innovation for a Prosperous Nation ";
export const inceptionandPurposeContent = "JIIICaS 2024 emerged from a collective desire to create a platform where ideas could flourish, innovations could be showcased, and collaborations could thrive. Our purpose is to ignite the spark of creativity, inspire groundbreaking solutions, and contribute to the advancement of knowledge and technology in achieving UiTM's mission toward Globally Renowned University (GRU2025) in 2025."; 
export const innovationContent = "As we embark on the latest edition of JIIICaS, we reflect on the legacy of innovation that has defined our past events. Over the years, we have witnessed the transformative power of ideas and the incredible impact that passionate individuals can have on shaping the future. ";
export const connectingContent = "What sets JIIICaS 2024 apart is the commitment to fostering connections. We believe that true innovation happens when diverse perspectives converge. Through our competitions, symposiums, and collaborative initiatives, we aim to create a vibrant ecosystem where participants can exchange ideas, learn from each other, and collectively push the boundaries of what is possible. ";
export const growthContent = "Behind JIIICaS 2024 is a dedicated team committed to nurturing innovation and fostering a sense of purpose. Together, we work towards creating an environment where ideas can flourish, innovations can grow, and sustainable solutions can thrive. ";
export const movementContent = "JIIICaS 2024 invites you to join the movement toward a future where innovation and sustainability go hand in hand. Whether you are a seasoned innovator, a budding entrepreneur, or simply someone passionate about positive change, we welcome you to be part of our journey toward a prosperous nation. ";



