import React, { Component } from 'react';
import categoryA from '../../image/category-a.png';
import categoryB from '../../image/category-b.png';
import categoryC from '../../image/category-c.png';

export default class Category extends Component {
    static displayName = Category.name;

    constructor(props) {

        super(props);
        this.state = {
     };
    }

    render() {

        return (
            <div>

                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <strong>Category</strong>

                            <hr style={{ width: '10%', margin: 'auto' }}></hr>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </section>

                <section id="category">
                    <div class="margin-centerText">
                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center'}}>
                                <div class="margin-centerWithWhiteBackground">
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <img class="scaleCategory" src={categoryA} alt="categoryA" /></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Category A</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Higher Educational Students / Any recognized institutions in Malaysia and international students </td>
                                    </tr>
                                </div>
                            </div>
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                                <div class="margin-centerWithWhiteBackground">
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <img class="scaleCategory" src={categoryB} alt="categoryB" /></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Category B</strong></td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Primary and Secondary School Students</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                </div>
                            </div>
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                                <div class="margin-centerWithWhiteBackground">
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <img class="scaleCategory" src={categoryC} alt="categoryC" /></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Category C</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Special Education Students</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>&nbsp;</div>
                                        </td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      </div>
    );
  }
}
