import React, { Component } from 'react';

export default class PosterGuideline extends Component {
    static displayName = PosterGuideline.name;

    constructor(props) {

        super(props);
        this.state = {
            posterTemplateLink:"https://docs.google.com/presentation/d/1IPXpBvySOcU7aRGRETpslyJxl_W_Q2Ni/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true"
        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#9bf2fa" }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <strong>Poster Guidelines</strong>

                            <hr style={{ width: '10%', margin: 'auto' }}></hr>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-8">
                                <p style={{ marginLeft: "15px" }}>
                                    <strong> *Poster Presentation is compulsory for those who attend the physical event JIIICaS 2024. </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-8">
                                <p style={{ marginLeft: "15px" }}>
                                    <strong>*Each project is required to bring a hard copy of the poster to the event.  </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-8">
                                <p style={{ marginLeft: "15px" }}>
                                    The guidelines for the poster are listed below.  
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <ol>
                                    <strong><li style={{ padding: 10 }}>Layout and format </li></strong>
                                    <p>Each poster should have a portrait layout and be in the A3 format (width x height) 297 mm x 420 mm. </p> 

                                    <strong><li style={{ padding: 10 }}>Poster heading  </li></strong>
                                    <p>Each poster should have a portrait layout and be in the A3 format (width x height) 297 mm x 420 mm.</p>

                                    <ul>
                                        <li style={{ padding: 10 }}>The poster heading should follow the poster template which can be downloaded from the JIIICaS website. </li>
                                        <li style={{ padding: 10 }}>Each poster should have the following items where preferably included in the poster heading:  </li>

                                        <ol type="a">
                                            <li style={{ padding: 10 }}>The JIICaS2024 logo is to be put on the above-left side of the poster </li>
                                            <li style={{ padding: 10 }}>The institution logo (if available) is to be put on the above right side of the poster. </li>
                                            <li style={{ padding: 10 }}>Full project title </li>
                                            <li style={{ padding: 10 }}>JIIICaS 2024 Project ID  </li>
                                            <li style={{ padding: 10 }}>Names of all the group members and the institution represented </li>
                                        </ol>

                                        <li style={{ padding: 10 }}>Download the poster template via this link: <a href="/#/more#download">click here</a></li>
                                    </ul>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
      </div>
    );
  }
}
