import React, { Component } from 'react';
import qrLogo from '../image/qr_logo.png';
export default class CustomFooter extends Component {
    static displayName = CustomFooter.name;
    render() {

        return (
            <div >
                <footer class="footer" style={{ backgroundColor: "#9bf2fa"}}>
                    <div class="margin-footer" style={{  padding: "50px" }}>
                        <div class="row">
                            <div class="col-12 col-md-6" style={{ paddingBottom: '10px' }}>
                                <h1>OFFICE ADDRESS</h1>
                                <div><hr></hr></div>
                                <table>
                                    <tr>
                                        <td> Secretariat JIIICaS2024,</td>
                                    </tr>
                                    <tr>
                                        <td>Kolej Pengajian Pengkomputeran, Informatik dan Matematik</td>
                                    </tr>
                                    <tr>
                                        <td>Universiti Teknologi MARA Cawangan Pasir Gudang,</td>
                                    </tr>
                                    <tr>
                                        <td>Jalan Purnama, Bandar Seri Alam, </td>
                                    </tr>
                                    <tr>
                                        <td>81750 Masai, Johor, Malaysia </td>
                                    </tr>
                                </table>
                                <div>&nbsp;</div>
                            </div>
                            <div class="col-12 col-md-6">
                                <h1>CONTACT INFORMATION </h1>
                                <div><hr></hr></div>
                                <table>
                                    <tr>
                                        <td> Any inquiries, please email to : <a href='secretariat@jiiicas.com'>secretariat@jiiicas.com</a> </td>
                                    </tr>
                                    <div>&nbsp;</div>
                                    <tr>
                                        <td> Or join the telegram group : <a href='https://t.me/+wTIgt7e5btthYzhl'>https://t.me/+wTIgt7e5btthYzhl</a></td>
                                    </tr>
                                    <div>&nbsp;</div>
                                    <tr>
                                        <td><strong>Scan to join telegram group</strong></td>
                                    </tr>
                                    <tr>
                                        <td><img class="scaleQR" src={qrLogo} alt="qrLogo" /></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="margin-centerBottomText">
                        Copyright &copy; {2024} JIICaS2024. All Rights Reserved.
                    </div>
                </footer>
      </div>
        );
    }
}
