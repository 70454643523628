import React, { Component } from 'react';
export class OrganisingCommiittee extends Component {
    static displayName = OrganisingCommiittee.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px"}}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Organising Committee</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-10">
                                <table id="tableWithHeader">
                                    <tr>
                                        <th>BIL</th>
                                        <th>AJK</th>
                                        <th>NAME</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>PATRON </td>
                                        <td>PROF. MADYA DR. SAUNAH ZAINON </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>ADVISOR 1  </td>
                                        <td>DR. SITI KHADIJAH ALIAS   </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>ADVISOR 2 </td>
                                        <td>
                                            PN. NUR INTAN SYAFINAZ AHMAD  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>CHAIRMAN </td>
                                        <td>DR. NORBAITI TUKIMAN  </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>VICE CHAIRMAN  </td>
                                        <td>DR. NUR IDAYU ALIMON  </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="2">6</td>
                                        <td>SECRETARY I</td>
                                        <td>AISHAH MAHAT  </td>
                                    </tr>
                                    <tr>
                                        <td>SECRETARY II</td>
                                        <td>TS MOHAMAD IMRAN ISMAIL  </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>TREASURER  </td>
                                        <td>NORZARINA JOHARI  </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>SECRETARIAT  </td>
                                        <td>DR. WAN MUNIRAH BINTI WAN MOHAMAD  </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>REGISTRATION  </td>
                                        <td>NURHAZIRAH MOHAMAD YUNOS  </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>CERTIFICATE, FEEDBACK AND ANALYSIS  </td>
                                        <td>DR. MOHAMAD FAIZAL BIN AB JABAL  </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td>11</td>*/}
                                    {/*    <td>WEBSITE  </td>*/}
                                    {/*    <td>MOHAMMAD DANIEL ISKANDAR BIN MOHD REZAL  </td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>11</td>
                                        <td>MULTIMEDIA   </td>
                                        <td>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>AZYAN YUSRA KAPI @ KAHBI</li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>NUR SYAFIKAH BINTI IBRAHIM</li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>MOHD HANAFI BIN AZMAN ONG</li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>MOHAMMAD DANIEL ISKANDAR BIN MOHD REZAL</li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>PROMOTION, PUBLICITY AND DOMESTIC AFFAIRS  </td>
                                        <td>AHMAD KHUDZAIRI KHALID  </td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td>JURY  AND AWARD</td>
                                        <td>AZRINA SUHAIMI  </td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td>ABSTRACT SCIENTIFIC, POSTER & PUBLICATION  </td>
                                        <td>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>NG SET FONG  </li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>FOO FONG YENG</li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td>SPONSORSHIP   </td>
                                        <td>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>DR. NUR IDAYU ALIMON </li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>NUR INTAN SYAFINAZ AHMAD</li>
                                            <li style={{ listStyle: "none", fontSize: 16 }}>DR. NORBAITI TUKIMAN </li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td>INTERNATIONAL AFFAIRS  </td>
                                        <td>DR. RASHIDAH MOKHTAR  </td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td>GIFT  </td>
                                        <td>HARSHIDA HASMY  </td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>PROTOCOL </td>
                                        <td>DR.NUR SYAMILAH ARIFIN  </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td>19</td>*/}
                                    {/*    <td>LOGISTIC, FACILITY AND VENUE PREPARATION   </td>*/}
                                    {/*    <td>MOHD ABDUL SHUKUR BIN MOHD. NOOR </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>21</td>*/}
                                    {/*    <td>TECHNICAL  </td>*/}
                                    {/*    <td>EN MOHD FERDAUS JAMIN </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>22</td>*/}
                                    {/*    <td>PHOTOGRAPHER & VIDEO  </td>*/}
                                    {/*    <td>EN MOHAMED FAKHRUL REDZA SAHIDEK  </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>23</td>*/}
                                    {/*    <td>OPENING CEREMONY, PROTOCOL, LIASON OFFICER, FLOOR MANAGER   </td>*/}
                                    {/*    <td>*/}
                                    {/*        <li style={{ listStyle: "none", fontSize: 16 }}>DR.NUR SYAMILAH ARIFIN </li>*/}
                                    {/*        <li style={{ listStyle: "none", fontSize: 16 }}>EN AHMAD SAUFI BIN AHMAD JAWAHIR  </li>*/}
                                    {/*        <li style={{ listStyle: "none", fontSize: 16 }}>CIK SITI MARIAM MOHAMMAD ILIYAS</li>*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td>24</td>*/}
                                    {/*    <td>OFFICIAL GIMMICK   </td>*/}
                                    {/*    <td>AHMAD NAJMIE RUSLI </td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td>19</td>
                                        <td>SYMPOSIUM AND BIZ SHOWCASE  </td>
                                        <td>MASMED COMMITEE & ENTREPREURSHIP CLUB  </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

      </div>
    );
  }
}
