import React, { Component } from 'react';

export class AwardsandPrizes extends Component {
    static displayName = AwardsandPrizes.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>JIIICas2024 Awards and Prizes</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div>
                        <div class="row" style={{ placeContent: 'center' }}>
                            <div class="col-12 col-md-10">
                                <table id="tableWithHeader">
                                    <tr style={{ fontSize: 18 }}>
                                        <th>Category</th>
                                        <th>Mode</th>
                                        <th>Awards</th>
                                        <th>Prizes</th>
                                    </tr>
                                    <tr>
                                        <td rowspan="3" style={{ fontSize: 18 }}>Category A
                                            <ol>
                                                <li>Social Sciences</li>
                                                <li>Science and Technology </li>
                                                <li>Project paper/idea </li>
                                            </ol>
                                        </td>
                                        <td style={{ fontSize: 18 }}>Online</td>
                                        <td>
                                            <li>Gold</li>
                                            <li>Silver</li>
                                            <li>Bronze</li>
                                            <li>Diamond (S&T)</li>
                                            <li>Platinum (SS)</li>
                                            <li>Emerald (Paper/idea)</li>
                                            <li>Crown (Best of the Best) </li>
                                        </td>
                                        <td>
                                            <li>E-certificate</li>
                                            <li>Medal for 4 awards (Diamond, Platinum, Emerald & Crown) in the closing ceremony</li>
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                        <td rowSpan="2" style={{ fontSize: 18 }}>Physical</td>
                                        <td>
                                            <li>Gold</li>
                                            <li>Silver</li>
                                            <li>Bronze</li>
                                        </td>
                                        <td>
                                            <li>E-certificate</li>
                                            <li>Medal</li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <li>Crown (Best of the Best)</li>
                                            <li>Diamond (S&T)</li>
                                            <li>Platinum (SS)</li>
                                            <li>Emerald (Paper/idea)</li>
                                            <li>Best Poster</li>
                                            <li>Best Booth </li>
                                        </td>
                                        <td>
                                            <li>E-certificate</li>
                                            <li>Medal for 6 awards in closing ceremony </li>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize:18} }>Category B and C
                                            <ol>
                                                <li>Primary and Secondary</li>
                                                <li>Special Education</li>
                                            </ol>
                                        </td>
                                        <td style={{ fontSize: 18 }}>Online</td>
                                        <td>
                                            <li>Gold</li>
                                            <li>Silver</li>
                                            <li>Bronze</li>
                                            <li>Champion (Primary, Secondary, and Pendidikan Khas)</li>
                                            <li>1st Runner-Up (Primary, Secondary, and Pendidikan Khas)</li>
                                            <li>2nd Runner-Up (Primary, Secondary, and Pendidikan Khas) </li>
                                        </td>
                                        <td>
                                            <li>E-certificate</li>
                                            <li>Medal for 9 awards (Champion, 1st  & 2nd  runner up) in closing ceremony </li>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

      </div>
    );
  }
}
