import { HomeBoard } from "./HomeBoard";
import { CallForRegistrationBoard } from "./CallForRegistrationBoard";
import { CallForJuryBoard } from "./CallForJuryBoard";
import { ResultBoard } from "./ResultBoard";
import { MoreBoard } from "./MoreBoard";

const AppRoutes = [
    {
        index: true,
        element: <HomeBoard />
    },
    {
        path: '/call-for-registration',
        element: <CallForRegistrationBoard />
    },
    {
        path: '/call-for-jury',
        element: <CallForJuryBoard />
    },
    {
        path: '/result',
        element: <ResultBoard />
    }
    ,
    {
        path: '/more',
        element: <MoreBoard />
    }
];

export default AppRoutes;
