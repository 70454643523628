import React, { Component } from 'react';

export default class JudgingCriteria extends Component {
    static displayName = JudgingCriteria.name;

    constructor(props) {

        super(props);
        this.state = {
     };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px"}}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>General Judging Criteria</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div>
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <ul>
                                    <li style={{ padding: 10 }}>Novelty/Uniqueness</li>
                                    <li style={{ padding: 10 }}>Inventiveness</li>
                                    <li style={{ padding: 10 }}>Relevance in solving the problem(s) concerned</li>
                                    <li style={{ padding: 10 }}>Contribution</li>
                                    <li style={{ padding: 10 }}>Market potential of the Invention/Innovation/ Design </li>
                                    <li style={{ padding: 10 }}>Status of the Invention/ Innovation/Design</li>
                                    <li style={{ padding: 10 }}>Knowledge and Presentation of Invention/Innovation/Design </li>
                                    <li style={{ padding: 10 }}>Presentation </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
      </div>
    );
  }
}
