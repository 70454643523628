import React, { Component } from 'react';
import CustomFooter from './CustomFooter';
import { Award } from './Result/Award';
import { Publication } from './Result/Publication';


export class ResultBoard extends Component {
    static displayName = ResultBoard.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <div class="section-full">

                    <div>&nbsp;</div>
                    <section id="/result#award">
                        <Award />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/result#publication">
                        <Publication />
                    </section>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <CustomFooter />

                </div>
      </div>
    );
  }
}
