import React, { Component } from 'react';

export class Publication extends Component {
    static displayName = Publication.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px",backgroundColor: "#9bf2fa" }}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>Publication</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="content" class="section-award" >
                    <div class="margin-centerText">
                        <h2>All submitted extended abstracts will undergo a peer review process. The selected extended abstract will be published in non-indexed JIIICaS2024 e-proceedings. </h2>
                    </div>
                </section>

      </div>
    );
  }
}
