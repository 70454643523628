import React, { Component } from 'react';
import CustomFooter from './CustomFooter';
import { Download } from './More/Download';
import { JIIICaSFaq } from './More/JIIICaSFaq';
import { OrganisingCommiittee } from './More/OrganisingCommiittee';
import { PosterEventTentative } from './More/PosterEventTentative';
import { VisitJohor } from './More/VisitJohor';


export class MoreBoard extends Component {
    static displayName = MoreBoard.name;

    constructor(props) {

        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div>
                <div class="section-full">

                    <div>&nbsp;</div>
                    <section id="/more#download">
                        <Download />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/more#postereventtentative">
                        <PosterEventTentative />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/more#organisingcommittee">
                        <OrganisingCommiittee />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/more#visitjohor">
                        <VisitJohor />
                    </section>

                    <div>&nbsp;</div>
                    <section id="/more#jiiicasfaq">
                        <JIIICaSFaq />
                    </section>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <CustomFooter />

                </div>
      </div>
    );
  }
}
