import React, { Component } from 'react';
import registrationSubmissionProcess from '../../image/registrationSubmissionProcess.png'


export default class RegistrationProcess extends Component {
    static displayName = RegistrationProcess.name;

    constructor(props) {

        super(props);
        this.state = {
            extendedEnglishVersionLink:"https://docs.google.com/document/d/1SMUk2Z6DXB2wUBEFmM60cY_Uf-0N0uyR/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            extendedMalayVersionLink: "https://docs.google.com/document/d/1LFubjXYm93X2CG75MUNT6ayORAb5cm6N/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractEnglishVersionLink: "https://docs.google.com/document/d/1aqocxA2FMb3_AJBKI6_ja53gHhYJHzVh/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractMalayVersionLink: "https://docs.google.com/document/d/1nWyQ--6s01eqTU0Cy-N5I0BSS12zcl1_/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true ",
            cmtLink: "https://cmt3.research.microsoft.com/JIIICaS2024",
            cmtUsermanualLink: "https://drive.google.com/file/d/1437wOTrPzI5Y77jVUkISeoUxQR1kiOjk/view?usp=sharing ",
            cmt3Link:"https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FJIIICaS2024"
        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#9bf2fa" }}>
            
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <strong>Registration and Submission Guidelines</strong>

                            <hr style={{ width: '10%', margin: 'auto' }}></hr>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div class="margin-centerText">
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <img class="scaleImage" src={registrationSubmissionProcess} alt="registrationSubmissionProcess" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <ol>
                                    <li style={{ padding: 10 }}>The registration needs to be done by submitting the abstract (for all categories). </li>
                                    <li style={{ padding: 10 }}>Only Category A will submit the extended abstract (only for those who want to publish). </li>
                                    <li style={{ padding: 10 }}>Extended Abstract Template (Category A) and Abstract Template (Category B)  and can be downloaded  <a href="/#/more#download">here.</a></li>
                                    <li style={{ padding: 10 }}>Kindly prepare your abstract/ extended abstract in MS Word (in *.docx format) and it must be written in English or Bahasa Melayu. Please refer to the template.</li>
                                    <li style={{ padding: 10 }}>Please refer to JIIICaS2024 <a href={this.state.cmtUsermanualLink}>CMT User manual.pdf  </a> for registration and submission guide using Microsoft CMT. </li>
                                    <li style={{ padding: 10 }}>Registration Link via Microsoft CMT: <a href={this.state.cmt3Link}>click here</a></li>
                                    <li style={{ padding: 10 }}>Registration is open from 1st March 2024 until 30th July 2024</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row" style={{ placeContent: 'center'}}>
                            <div class="col-12 col-md-8">
                                <p style={{ marginLeft: "15px" }}> 
                                    <strong>Note: Submission that do not meet the requirements will be rejected. Accepted paper will have the opportunity to submit the Extended Abstract for non-indexed publication (Extended Abstract publication in 2024). </strong>
                                </p>
                            </div>
                        </div>
                       
                    </div>
                </section>
      </div>
    );
  }
}
