import React, { Component } from 'react';
import redoneLogo from '../../image/redone_logo.png';
import tekunLogo from '../../image/tekun_logo.png';

export default class Sponsorship extends Component {
    static displayName = Sponsorship.name;
    render() {

        return (
            <div >
                <div class="margin-centerText" style={{ padding: "50px" }}>
                    <div style={{ fontSize: '24px' }}>
                        <strong>Sponsorship</strong>

                        <hr style={{ width: '10%', margin: 'auto' }}></hr>
                        <div>&nbsp;</div>
                    </div>
                    <div class="row" style={{ placeContent: 'center' }} >
                        <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td>
                                        <img class="scaleSponsorLogo" src={redoneLogo} alt="redoneLogo" />
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td><strong>Red One</strong></td>*/}
                                {/*</tr>*/}

                            </div>
                        </div>
                        <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                            <div>
                                <tr>
                                    <td>
                                        <img class="scaleSponsorLogo" src={tekunLogo} alt="tekunLogo" />
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td><strong>Tekun Nasional</strong></td>*/}
                                {/*</tr>*/}

                            </div>
                        </div>                    
                    </div>              
                </div>

                {/*<table class="centerTable">*/}
                {/*    <tr>*/}
                {/*        <th style={{ padding: '20px' }}>*/}
                {/*            <img src={kjnLogo} alt="kjnLogo" style={{ width: 250, height: 220 }} />*/}
                {/*        </th>*/}
                {/*        <th style={{ padding: '20px' }}>*/}
                {/*            <img src={mbjbLogo} alt="mbjbLogo" style={{ width: 450, height: 220 }} />*/}
                {/*        </th>*/}
                {/*        <th style={{ padding: '20px' }}>*/}
                {/*            */}{/* <img src={kjnLogo} alt="kjnLogo" style={{ width: '250px', height: '220px' }} />*/}
                {/*        </th>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th>*/}
                {/*            Kerajaan Negeri Johor*/}
                {/*        </th>*/}
                {/*        <th>*/}
                {/*            Majlis Bandaraya Johor Bahru*/}
                {/*        </th>*/}
                {/*        <th>*/}
                {/*            UNIBA?*/}
                {/*        </th>*/}
                {/*    </tr>*/}
                {/*</table>*/}
                <div>&nbsp;</div>
                {/*<table class="centerTable">*/}
                {/*    <tr>*/}
                {/*        <th style={{ padding: '20px' }}>*/}
                {/*            <img src={utmLogo} alt="utmLogo" style={{ width: '220px', height: '220px' }} />*/}
                {/*        </th>*/}
                {/*        <th style={{ padding: '20px' }}>*/}
                {/*            <img src={pisLogo} alt="pisLogo" style={{ width: '220px', height: '220px' }} />*/}
                {/*        </th>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th>*/}
                {/*            Universiti Teknologi Malaysia*/}
                {/*        </th>*/}
                {/*        <th>*/}
                {/*           Politeknik Ibrahim Sultan*/}
                {/*        </th>*/}
                {/*    </tr>*/}
                {/*</table>*/}
            </div>
        );
    }
}
