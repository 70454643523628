import React, { Component } from 'react';

export class JIIICaSFaq extends Component {
    static displayName = JIIICaSFaq.name;

    constructor(props) {

        super(props);
        this.state = {

        };

        let answers = document.querySelectorAll(".accordion");
        answers.forEach((event) => {
            event.addEventListener("click", () => {
                if (event.classList.contains("active")) {
                    event.classList.remove("active");
                } else {
                    event.classList.add("active");
                }
            });
        });
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px"}}>
                <section id="title">
                    <div class="margin-centerText">
                        <div style={{ fontSize: '24px' }}>
                            <div style={{ fontSize: '24px' }}>
                                <strong>JIIICaS2024 FAQ</strong>

                                <hr style={{ width: '10%', margin: 'auto' }}></hr>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="container">
                    <details>
                        <summary>What is the primary objective of this conference?</summary>
                        <div>
                            The main goal of this conference is to foster collaboration, share innovative ideas, and promote advancements in invention through meaningful discussions, presentations, and networking opportunities. 
                        </div>
                    </details>
                    <details>
                        <summary>How many participants can join JIIICaS2024 in one group? </summary>
                        <div>
                            A maximum of 6 people per group including the lecturer/teacher. Student participation is compulsory in each group.  
                        </div>
                    </details>
                    <details>
                        <summary>Can the advisor/lecturer/teacher be a team member? </summary>
                        <div>
                            Yes. A maximum of 2 advisors/lecturers/teachers can be group members. The rest must be students
                        </div>
                    </details>
                    <details>
                        <summary>Who can be the team leader? </summary>
                        <div>
                            The team leader must be a student. Advisors/lecturers/teachers can only be group members. 
                        </div>
                    </details>
                    <details>
                        <summary>Will participants receive PAJSK (Participation) and PAJSK (Achievement)? </summary>
                        <div>
                            Yes. Participation recognition will be given to all students involved. PAJSK awards will be given to groups that achieve Champion, 1st, and 2nd runner-up. 
                        </div>
                    </details>
                    <details>
                        <summary>Will the Champion, 1st, and 2nd runner-up be awarded for each school student category? </summary>
                        <div>
                            Yes. For each category: Category B (Primary), Category B (Secondary), and Category C (Special Education). 
                        </div>
                    </details>
                    <details>
                        <summary>Where can I obtain the PAJSK letter? </summary>
                        <div>
                            It can be downloaded from the website.
                        </div>
                    </details>
                    <details>
                        <summary>Must all competing innovations meet sustainability elements? </summary>
                        <div>
                            No. All types of innovations will be accepted. However, innovations with sustainability elements will receive bonus points. 
                        </div>
                    </details>
                    <details>
                        <summary>Can one participate in more than one entry? </summary>
                        <div>
                            Yes. The project leader needs to submit a new registration. 
                        </div>
                    </details>
                    <details>
                        <summary>Is it mandatory to upload an abstract? </summary>
                        <div>
                            Yes. All entries must upload an abstract for all categories. 
                        </div>
                    </details>
                    <details>
                        <summary>Is it mandatory to upload an extended abstract? </summary>
                        <div>
                            No. Only Category A is allowed to submit an extended abstract for those who wish to publish only. 
                        </div>
                    </details>
                    <details>
                        <summary>Where will the publication take place? </summary>
                        <div>
                            The publication will be in the non-indexed e-proceeding of JIIICaS2024. It will be published on the website after the program concludes. 
                        </div>
                    </details>
                    <details>
                        <summary>Are vocational college students included in Category A?</summary>
                        <div>
                            For students pursuing a diploma, they are included in Category A. 
                        </div>
                    </details>
                </div>

            </div>
    );
  }
}
