import React, { Component } from 'react';

import imagebuilding from '../../image/academic-building.png';
export class Download extends Component {
    static displayName = Download.name;

    constructor(props) {

        super(props);
        this.state = {
            extendedEnglishVersionLink: "https://docs.google.com/document/d/1SMUk2Z6DXB2wUBEFmM60cY_Uf-0N0uyR/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            extendedMalayVersionLink: "https://docs.google.com/document/d/1LFubjXYm93X2CG75MUNT6ayORAb5cm6N/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractEnglishVersionLink: "https://docs.google.com/document/d/1aqocxA2FMb3_AJBKI6_ja53gHhYJHzVh/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            abstractMalayVersionLink: "https://docs.google.com/document/d/1nWyQ--6s01eqTU0Cy-N5I0BSS12zcl1_/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true ",
            cmtLink: "https://drive.google.com/file/d/1jVSjaM1tptRQaxkNVeVZKPUBWcps1lvK/view?usp=sharing ",
            posterTemplateLink: "https://docs.google.com/presentation/d/1IPXpBvySOcU7aRGRETpslyJxl_W_Q2Ni/edit?usp=sharing&ouid=110216073393645909346&rtpof=true&sd=true",
            pAJSKLetter: "https://drive.google.com/file/d/1vtTyIsxQW5GIHj9c7s6J7mYSyIUe1X4L/view?usp=sharing",
        };
    }

    render() {

        return (
            <div style={{ paddingTop: "50px", paddingBottom: "50px"}}>
                <div class="margin-centerText">
                    <div style={{ fontSize: '24px' }}>
                        <div style={{ fontSize: '24px' }}>
                            <strong>Download</strong>

                            <hr style={{ width: '10%', margin: 'auto' }}></hr>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </div>

                <div class="margin-countdown">
                    <div>
                        <a href={this.state.abstractEnglishVersionLink}><button class="button-darkTurqoise"><strong>Abstract (Eng) Template</strong></button></a>
                    </div>

                    <div>
                        <a href={this.state.abstractMalayVersionLink}><button class="button-darkTurqoise"><strong>Abstract (BM) Template</strong></button></a>
                    </div>

                    <div>
                        <a href={this.state.extendedEnglishVersionLink}><button class="button-darkTurqoise"><strong>Extended Abstract (Eng) Template &mdash; Category A</strong></button></a>
                    </div>

                    <div>
                        <a href={this.state.extendedMalayVersionLink}><button class="button-darkTurqoise"><strong>Extended Abstract (BM) Template &mdash; Category A </strong></button></a>
                    </div>

                    <div>
                        <a href={this.state.posterTemplateLink}><button class="button-darkTurqoise"><strong>Poster Template</strong></button></a>
                    </div>

                    <div>
                        <a href={this.state.cmtLink}><button class="button-darkTurqoise"><strong>Manual CMT3 </strong></button></a>
                    </div>

                    <div>
                       <button class="button-darkTurqoise"><strong>Program Book </strong></button>
                    </div>

                    <div>
                        <a href={this.state.pAJSKLetter}><button class="button-darkTurqoise"><strong>PAJSK Letter</strong></button></a>
                    </div>

                </div>

            </div>
    );
  }
}
