import React, { Component } from 'react';
import CustomFooter from './CustomFooter';


export class CallForJuryBoard extends Component {
    static displayName = CallForJuryBoard.name;

    constructor(props) {

        super(props);
        this.state = {
            googleFormLink: "https://forms.gle/s8eThTWurD1JW8a1A"
        };
    }

    render() {

        return (
            <div>
                <div class="section-full">

                    <section id="content" class="section-jury">
                        <div class="margin-centerText">
                            <h2>Registration for jury is opened from 28th July until 10th August 2024</h2>
                            <br />
                          <a href={this.state.googleFormLink} ><button class="button register">Click here to register</button></a>
                        </div>
                    </section>

                    <CustomFooter />

                </div>
      </div>
    );
  }
}
